<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">User Details : {{ user.first_name }} {{ user.last_name }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="user">
            <tbody>
                <tr v-for="(v, k, index) in user" :key="index" v-if="k !== 'id' && k !== 'all_groups' ">
                    <th>{{ k|capitalize }}</th>
                    <td v-if="k === 'groups'">{{ userGroups }}</td>
                  <td v-else>{{ v }}</td>
                </tr>
            </tbody>
        </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button v-show="hasFullAccess" :to="{ name: 'SCUserEdit', params: { id: user.id } }" variant="outline-dark" class="float-right">Edit</b-button>
          <b-button v-show="hasFullAccess" :to="{ name: 'SCUserSetPassword', params: { id: user.id } }" variant="outline-dark" class="float-right mr-2">Set Password</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";

  export default {
    name: 'User',
    components: {Id},
    props: {
      caption: {
        type: String,
        default: 'User Details'
      },
    },
    data: () => {
      return {
        user: null,
        pdf: null,
        pdf_link: null,
        get_document: false
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getUser() {
        axios.get('/users/' + this.$route.params.id + '/').then(
          response => {
            this.user = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('premium error');
            console.log(error.response)
          })
        return this.user
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      userGroups() {
        let groups = ''

        if (this.user) {

          this.user.all_groups.filter(item => {

            let i = 0;

            this.user.groups.forEach(group => {

              if (item.value === group) {
                groups += item.text

                i += 1;

                if (this.user.groups.length > 1) {
                    groups += ', '
                }
              }
            })


          })
        }

        return groups

      },
    },
    mounted() {
      this.getUser()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getUser()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
